/*
// Text Toggle for click events
*/ 


const dataTextItems = document.querySelectorAll("[data-text-toggle]");

dataTextItems.forEach(dataText => {

    let initialText = dataText.innerText;
    let textToggle = dataText.dataset.textToggle;

    dataText.addEventListener("click", e => {
        dataText.innerText === initialText
            ? (dataText.innerText = textToggle)
            : (dataText.innerText = initialText);
    });
    
});
